<div class="table-container">
    <table class="mobi-ui-table-major oiv-table">
        @if (headerRowCount() > 0) {
            <thead>
                @for (row of tableHeader(); track row.eId) {
                    <tr>
                        @for (cell of returnContentArray(row); track cell.eId; let i = $index) {
                            <th
                                class="mobi-ui-padding-md"
                                [style.width.%]="getRowSize(i)"
                                [attr.rowspan]="getRowSpan(cell) > 0 ? getRowSpan(cell) : null"
                                [attr.colspan]="getColSpan(cell) > 0 ? getColSpan(cell) : null"
                            >
                                <!-- This is one cell -->
                                <oiv-viewer-render-components [content]="cell" />
                            </th>
                        }
                    </tr>
                }
            </thead>
        }

        @if (this.bodyRowCount() > 0) {
            <tbody>
                @for (row of tableBody(); track row.eId) {
                    <tr>
                        <!-- This is one row -->
                        @for (cell of returnContentArray(row); track cell.eId; let i = $index) {
                            <td
                                class="mobi-ui-padding-md"
                                [style.width.%]="getRowSize(i)"
                                [attr.rowspan]="getRowSpan(cell) > 0 ? getRowSpan(cell) : null"
                                [attr.colspan]="getColSpan(cell) > 0 ? getColSpan(cell) : null"
                            >
                                <!-- This is one cell -->
                                <oiv-viewer-render-components [content]="cell" />
                            </td>
                        }
                    </tr>
                }
            </tbody>
        }

        @if (this.footerRowCount() > 0) {
            <tfoot>
                @for (row of tableFooter(); track row.eId) {
                    <tr>
                        @for (cell of returnContentArray(row); track cell.eId; let i = $index) {
                            <td
                                class="mobi-ui-padding-md"
                                [style.width.%]="getRowSize(i)"
                                [attr.rowspan]="getRowSpan(cell) > 0 ? getRowSpan(cell) : null"
                                [attr.colspan]="getColSpan(cell) > 0 ? getColSpan(cell) : null"
                            >
                                <!-- This is one cell -->
                                <oiv-viewer-render-components [content]="cell" />
                            </td>
                        }
                    </tr>
                }
            </tfoot>
        }
    </table>
</div>
